$(document).foundation();

$(document).ready(function()
{
    // cookie box
    $( '.cookie-button' ).click( function()
    {
        Cookies.set( 'cookie_notice', '1', { expires: 365, path: '/' } );
        $( '.box-cookie' ).slideUp();

        return false;
    });


    if( Cookies.get( 'cookie_notice' ) )
    {
        $( '.box-cookie' ).hide();
    }
    else
    {
        $( '.box-cookie' ).show();
    }


    $('header').headroom({
        "offset": 50,
        "tolerance": 5
    });


    $('.main-items').owlCarousel({
        animateOut: 'fadeOutUp',
        animateIn: 'fadeInUp',
        items:1,
        loop:true,
        dots:false,
        autoplay:true,
        autoplayTimeout:8200,
        
        autoplayHoverPause:true,
        responsive:{
            0:{
                nav:false
            },
            641:{
                nav:false
            },
            1025:{
                nav:false
            }
        }
    });
});